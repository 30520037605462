<template>
  <div>
    <model title="Checkbox" tag="复选框">
      <h3>基础用法</h3>
      <p>通过 fontSize 属性控制复选框的大小。</p>
      <div>
        <lh-checkbox v-model="checkbox1" fontSize="20px">复选框1</lh-checkbox>
        <lh-checkbox v-model="checkbox2" fontSize="25px">复选框2</lh-checkbox>
        <lh-checkbox v-model="checkbox3" fontSize="30px">复选框3</lh-checkbox>
      </div>
      <p>通过 disabled 属性可以将组件设置为禁用状态。</p>
      <div><lh-checkbox v-model="checkbox4" disabled>复选框4</lh-checkbox></div>
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code1"></lh-code-editor>
      </lh-collapse>
      <h3>复选框组</h3>
      <p style="text-indent:2em;">通过 name 属性可以将同名复选框设为一组，然后通过 lh-checkbox-controller 组件的 min 属性和 max 属性可以设置“最小选择数”和“最大选择数”。如果不希望显示 lh-checkbox-controller 组件，可以通过 v-show="false" 隐藏组件。</p>
      <lh-checkbox-controller :min="1" :max="3" name="checkbox">全选</lh-checkbox-controller>
      <div>
        <lh-checkbox v-model="checkboxList[0]" name="checkbox">选项1</lh-checkbox>
        <lh-checkbox v-model="checkboxList[1]" name="checkbox">选项2</lh-checkbox>
        <lh-checkbox v-model="checkboxList[2]" name="checkbox">选项3</lh-checkbox>
        <lh-checkbox v-model="checkboxList[3]" name="checkbox">选项4</lh-checkbox>
      </div>
      <lh-collapse>
        <div slot="title">示例代码</div>
        <lh-code-editor v-model="code2"></lh-code-editor>
      </lh-collapse>
      <h3>属性事件参考表</h3>
      <h4>lh-checkbox 属性表：</h4>
      <lh-table>
        <lh-tr>
          <lh-th>参数</lh-th>
          <lh-th>说明</lh-th>
          <lh-th>类型</lh-th>
          <lh-th>可选值</lh-th>
          <lh-th>默认值</lh-th>
        </lh-tr>
        <lh-tr>
          <lh-td>value(v-model)</lh-td>
          <lh-td>复选框值，是否选中</lh-td>
          <lh-td>Boolean</lh-td>
          <lh-td>true | false</lh-td>
          <lh-td>false</lh-td>
        </lh-tr>
        <lh-tr>
          <lh-td>fontSize</lh-td>
          <lh-td>复选框大小(包含文字)</lh-td>
          <lh-td>String</lh-td>
          <lh-td>-</lh-td>
          <lh-td>20px</lh-td>
        </lh-tr>
        <lh-tr>
          <lh-td>disabled</lh-td>
          <lh-td>是否禁用复选框</lh-td>
          <lh-td>Boolean</lh-td>
          <lh-td>true | false</lh-td>
          <lh-td>false</lh-td>
        </lh-tr>
        <lh-tr>
          <lh-td>activeColor</lh-td>
          <lh-td>激活颜色</lh-td>
          <lh-td>String</lh-td>
          <lh-td>-</lh-td>
          <lh-td>#409EFF</lh-td>
        </lh-tr>
        <lh-tr>
          <lh-td>name</lh-td>
          <lh-td>名称（用于分组）</lh-td>
          <lh-td>String</lh-td>
          <lh-td>-</lh-td>
          <lh-td>null</lh-td>
        </lh-tr>
      </lh-table>

      <h4>lh-checkbox 事件表：</h4>
      <lh-table>
        <lh-tr>
          <lh-th>事件名称</lh-th>
          <lh-th>说明</lh-th>
          <lh-th>回调参数</lh-th>
        </lh-tr>
        <lh-tr>
          <lh-td>change</lh-td>
          <lh-td>当绑定值变化时触发的事件</lh-td>
          <lh-td>更新后的值</lh-td>
        </lh-tr>
      </lh-table>

      <h4>lh-checkbox-controller 属性表：</h4>
      <lh-table>
        <lh-tr>
          <lh-th>参数</lh-th>
          <lh-th>说明</lh-th>
          <lh-th>类型</lh-th>
          <lh-th>可选值</lh-th>
          <lh-th>默认值</lh-th>
        </lh-tr>
        <lh-tr>
          <lh-td>fontSize</lh-td>
          <lh-td>组件大小(包含文字)</lh-td>
          <lh-td>String</lh-td>
          <lh-td>-</lh-td>
          <lh-td>20px</lh-td>
        </lh-tr>
        <lh-tr>
          <lh-td>name</lh-td>
          <lh-td>名称</lh-td>
          <lh-td>String</lh-td>
          <lh-td>-</lh-td>
          <lh-td>null</lh-td>
        </lh-tr>
        <lh-tr>
          <lh-td>min</lh-td>
          <lh-td>最少选中数</lh-td>
          <lh-td>Number</lh-td>
          <lh-td>-</lh-td>
          <lh-td>0</lh-td>
        </lh-tr>
        <lh-tr>
          <lh-td>max</lh-td>
          <lh-td>最少选中数（值为null时表示不限数量）</lh-td>
          <lh-td>Number</lh-td>
          <lh-td>-</lh-td>
          <lh-td>null</lh-td>
        </lh-tr>
      </lh-table>
    </model>
  </div>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"CheckboxView",
  data(){
    return {
      checkbox1:true,
      checkbox2:false,
      checkbox3:false,
      checkbox4:true,
      checkboxList:[
        true,
        false,
        true,
        false
      ],
      code1:
        "<template>\n"+
        "  <div>\n"+
        "    <lh-checkbox v-model=\"checkbox1\" fontSize=\"20px\">复选框1</lh-checkbox>\n"+
        "    <lh-checkbox v-model=\"checkbox2\" fontSize=\"25px\">复选框2</lh-checkbox>\n"+
        "    <lh-checkbox v-model=\"checkbox3\" fontSize=\"30px\">复选框3</lh-checkbox>\n"+
        "    <lh-checkbox v-model=\"checkbox4\" disabled>复选框4</lh-checkbox>\n"+
        "  </div>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "    return {\n"+
        "      checkbox1:true,\n"+
        "      checkbox2:false,\n"+
        "      checkbox3:false\n"+
        "    }\n"+
        "  }\n"+
        "}\n"+
        "</scrip"+"t>",
      code2:
        "<template>\n"+
        "  <div>\n"+
        "    <lh-checkbox-controller :min=\"1\" :max=\"3\" name=\"checkbox\">全选</lh-checkbox-controller>\n"+
        "    <div>\n"+
        "      <lh-checkbox v-model=\"checkboxList[0]\" name=\"checkbox\">选项1</lh-checkbox>\n"+
        "      <lh-checkbox v-model=\"checkboxList[1]\" name=\"checkbox\">选项2</lh-checkbox>\n"+
        "      <lh-checkbox v-model=\"checkboxList[2]\" name=\"checkbox\">选项3</lh-checkbox>\n"+
        "      <lh-checkbox v-model=\"checkboxList[3]\" name=\"checkbox\">选项4</lh-checkbox>\n"+
        "    </div>\n"+
        "  </div>\n"+
        "</template>\n\n"+
        "<script>\n"+
        "export default {\n"+
        "  data(){\n"+
        "   return {\n"+
        "     checkboxList:[\n"+
        "       true,\n"+
        "       false,\n"+
        "       true,\n"+
        "       false\n"+
        "     ],\n"+
        "   }\n"+
        "  }\n"+
        "}\n"+
        "</scrip"+"t>"
    }
  }
}
</script>

<style lang="less" scoped>

</style>